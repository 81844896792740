const IconFacebook = () => (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.8512 2.05029H15.7762C14.4169 2.05029 13.1134 2.59025 12.1523 3.55137C11.1911 4.51249 10.6512 5.81606 10.6512 7.17529V10.2503H7.57617V14.3503H10.6512V22.5503H14.7512V14.3503H17.8262L18.8512 10.2503H14.7512V7.17529C14.7512 6.90345 14.8592 6.64273 15.0514 6.45051C15.2436 6.25828 15.5043 6.15029 15.7762 6.15029H18.8512V2.05029Z"
            stroke="currentColor"
            strokeWidth="1.5375"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default IconFacebook;
